import React, { useState } from "react";
import { Container, Form, Button, Alert } from "react-bootstrap";
import emailjs from "emailjs-com";
import "./Contact.css";
import {BiPaperPlane} from "react-icons/bi"
import {MdWhatsapp} from "react-icons/md";
import { Helmet } from "react-helmet";
import GoogleMapsComponent from "./Map";

const ContactForm = () => {

  const mapName = 'Preprockets Digital Services'; // Name of the map location
  const mapDescription = 'Google Maps showing Preprockets Digital Services'; // Description of the map
  const mapLatitude = '26.890708076660296'; // Latitude of the map location
  const mapLongitude = '81.05525187522358'; // Longitude of the map location
  const embedUrl = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3558.402821572815!2d81.05525187522358!3d26.890708076660296!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2ce531f503ce4a25%3A0x57166aee9b70844!2sPreprockets%20Digital%20Services!5e0!3m2!1sen!2sin!4v1712502768391!5m2!1sen!2sin'; // URL of embedded map

  const whatsappMessage = "Hello, I would like to connect with you regarding digital services.";

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [alertMessage, setAlertMessage] = useState("");

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_n26knlr",
        "template_8bpe58q",
        e.target,
        "uPZlUBw-tprQkeXfX"
      )
      .then(
        (result) => {
          console.log(result.text);
          setAlertMessage("Your message has been sent successfully!");
          setFormData({ name: "", email: "", phone: "", message: "" });
        },
        (error) => {
          console.log(error.text);
          setAlertMessage(
            "There was an error sending your message. Please try again later."
          );
        }
      );
  };

  return (
    <div className="outer-container">

<Helmet>
        <title>Contact Us - Preprockets Digital Services</title>
        <meta name="description" content="Contact Preprockets Digital Services for strategic digital marketing solutions that drive growth, enhance visibility, and maximize ROI. Empower your business to thrive in the digital age with tailored strategies designed to unlock your full potential and achieve sustainable success." />
        <meta name="keywords" content="digital marketing agency, strategic digital solutions, business growth strategies, online visibility, maximize ROI, innovative digital strategies, sustainable business success, global SEO services, international business growth, client acquisition, business potential, top digital marketing agency, SEO services, digital marketing solutions, leading solutions provider, digital marketing trends, SEO techniques, Uttar Pradesh, Lucknow" />
        <meta property="og:type" content="organization" />
        <meta property="og:url" content="https://www.preprockets.com" />
        <meta property="og:title" content="Preprockets Digital Services" />
        <meta property="og:description" content="At Preprockets Digital Services, we go beyond traditional digital marketing. We're your strategic partner in navigating the dynamic digital landscape, delivering tailored solutions that drive growth, enhance visibility, and maximize ROI. Our mission is clear: empower businesses to thrive in the digital age by unlocking their full potential and achieving sustainable success through innovative strategies." />
        <meta property="og:image" content="https://expensetracker12911.s3.amazonaws.com/Logo+Png-01-fotor-bg-remover-2024040211621.png" />
        <meta name="twitter:site" content="@Preprockets"/>

<meta name="twitter:title" content="Preprockets Digital Services"/>
<meta name="twitter:description" content="Preprockets Digital Services: Your trusted partner in digital success."/>
<meta name="twitter:image" content="https://pbs.twimg.com/profile_images/1775109624561356800/V9TrS4pd_400x400.jpg"/>


  <meta property="og:title" content="Preprockets Digital Services"/>
  <meta property="og:description" content="Preprockets Digital Services. Apne Business Ko De Ek Nayi Udaan!"/>
  <meta property="og:image" content="https://instagram.flko7-3.fna.fbcdn.net/v/t51.2885-19/435145071_1145834283075755_3259311141175043805_n.jpg?_nc_ht=instagram.flko7-3.fna.fbcdn.net&_nc_cat=111&_nc_ohc=BHsEQt5AztwQ7kNvgH-dP5K&edm=AEhyXUkBAAAA&ccb=7-5&oh=00_AYBbSArwYPew9XKWC6Qv7CqF_UF80nEQ4i6_BCb9AWGWFQ&oe=66984FAD&_nc_sid=8f1549%22"/>
  <meta property="og:url" content="https://www.instagram.com/preprockets/"/>
  <meta property="og:type" content="website"/>

  <meta property="og:title" content="Preprockets Digital Services"/>
  <meta property="og:description" content="Preprockets Digital Services. Apne Business Ko De Ek Nayi Udaan!"/>
  <meta property="og:image" content="https://scontent.flko7-4.fna.fbcdn.net/v/t39.30808-1/434336296_122096362100270093_746723831520368476_n.jpg?stp=dst-jpg_p200x200&_nc_cat=102&ccb=1-7&_nc_sid=f4b9fd&_nc_ohc=otuOfad8qEsQ7kNvgHhGWew&_nc_ht=scontent.flko7-4.fna&oh=00_AYB7Ylrmk9eoP7b0rSODstj7cCILg-BDYc12VEzLm-yc_w&oe=669861CB"/>
  <meta property="og:url" content="https://www.facebook.com/profile.php?id=61558102794628"/>
  <meta property="og:type" content="website"/>

  <meta property="og:title" content="Preprockets Digital Services"/>
  <meta property="og:description" content="Preprockets Digital Services. Apne Business Ko De Ek Nayi Udaan. preprockets.com . +91-921 925 3496"/>
  <meta property="og:image" content="https://yt3.googleusercontent.com/wSI9wtheE1wCAV4RfQnv_Bsr3aMu7KSjoxD_XK_XQCZqVRx_zYQR2eV1sOWMl6CIncOsHleH=s160-c-k-c0x00ffffff-no-rj"/>
  <meta property="og:url" content="https://www.youtube.com/@Preprockets"/>
  <meta property="og:type" content="video.other"/>

  <meta name="geo.position" content="26.8943375;81.0589693"/>
<meta name="geo.placename" content="Preprockets Digital Services | Digital Marketing Agency | Website Design Services | SEO Services | Social Media Marketing"/>
<meta name="geo.region" content="Country Code"/>



      </Helmet>
  


    <Container>

    <Container  style={{textAlign:"center",fontFamily:"Montserrat",fontSize: "1.5rem"}} className="pt-5"> 
        <span style={{color:"white"}}><a href="https://www.preprockets.com/" style={{color:"orange"}}>Home</a> / Contact Us</span>
      </Container>

    <div className="contact-form-bg" name="contactus">
      <Container className="contact-form-container">
        <h1 className="text-center m-4" style={{fontWeight:"bold"}}>Contact Us & Get In Touch</h1>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formName">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Form.Group controlId="formEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter your email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Form.Group controlId="formPhone">
            <Form.Label>Contact Number</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your contact number"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Form.Group controlId="formMessage">
            <Form.Label>Message</Form.Label>
            <Form.Control
              as="textarea"
              rows={5}
              placeholder="Enter your message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Button className="contact-us-btn" type="submit">
            Send Message Now <span><BiPaperPlane className="paper-icon"/></span>
          </Button>
        </Form>

        {alertMessage && (
          <Alert variant="success" className="mt-3">
            {alertMessage}
          </Alert>
        )}
      </Container>
    </div>
    </Container>
    <div>
      <GoogleMapsComponent/>
    </div>
    <MdWhatsapp
          className="whatsapp-icon"
          onClick={() =>
            window.open(
              `https://api.whatsapp.com/send?phone=+919219253496&text=${encodeURIComponent(
                whatsappMessage
              )}`,
              "_blank"
            )
          }
        />
    </div>
  );
};

export default ContactForm;
