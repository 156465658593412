import React from "react";
import { Container, Row, Col,Card, Stack } from "react-bootstrap";
import { MdWhatsapp } from "react-icons/md";
import { Helmet } from "react-helmet";
import {
  FaBullseye,
  FaChartLine,
  FaUsers,
  FaHandshake,
} from "react-icons/fa"; // Proper React Icons
import "./Aboutus.css";

const AboutUs = () => {


  const mapName = 'Preprockets Digital Services'; // Name of the map location
  const mapDescription = 'Google Maps showing Preprockets Digital Services'; // Description of the map
  const mapLatitude = '26.890708076660296'; // Latitude of the map location
  const mapLongitude = '81.05525187522358'; // Longitude of the map location
  const embedUrl = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3558.402821572815!2d81.05525187522358!3d26.890708076660296!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2ce531f503ce4a25%3A0x57166aee9b70844!2sPreprockets%20Digital%20Services!5e0!3m2!1sen!2sin!4v1712502768391!5m2!1sen!2sin'; // URL of embedded map


  const whatsappMessage = "Hello, I would like to connect with you regarding digital services.";

  return (
  <div>
    <Helmet>
        <title>About Us - Preprockets Digital Services</title>
        <meta name="description" content="Discover how Preprockets Digital Services empowers businesses with tailored digital marketing solutions that drive growth, enhance visibility, and maximize ROI. Partner with us to unlock your full potential and achieve sustainable success in the digital age"/>
        <meta name="keywords" content="Digital marketing agency, Strategic digital solutions, Business growth strategies, Enhanced online visibility, Maximize ROI, Innovative digital strategies, Sustainable business success, Navigating digital landscape, Targeted client acquisition, Unlock business potential, Top digital marketing agency, Global SEO services, International online visibility, Global business growth strategies, Worldwide client acquisition, Leading digital solutions provider, Global market competition, International SEO expertise, Global digital landscape navigation, Maximize ROI globally, Best digital marketing agency in India, SEO services in Lucknow, Digital marketing solutions in Uttar Pradesh, Top SEO company in Lucknow, Online visibility in India, Business growth strategies in Lucknow, Local client acquisition in Uttar Pradesh, Leading digital solutions provider in Lucknow, Digital marketing trends in India, SEO techniques for Indian businesses." />
        <meta property="og:type" content="organization" />
        <meta property="og:url" content="https://www.preprockets.com" />
        <meta property="og:title" content="Preprockets Digital Services" />
        <meta property="og:description" content="At Preprockets Digital Services, we go beyond traditional digital marketing. We're your strategic partner in navigating the dynamic digital landscape, delivering tailored solutions that drive growth, enhance visibility, and maximize ROI. Our mission is clear: empower businesses to thrive in the digital age by unlocking their full potential and achieving sustainable success through innovative strategies." />
        <meta property="og:image" content="https://expensetracker12911.s3.amazonaws.com/Logo+Png-01-fotor-bg-remover-2024040211621.png" />

<meta name="twitter:site" content="@Preprockets"/>
<meta name="twitter:title" content="Preprockets Digital Services"/>
<meta name="twitter:description" content="Preprockets Digital Services: Your trusted partner in digital success."/>
<meta name="twitter:image" content="https://pbs.twimg.com/profile_images/1775109624561356800/V9TrS4pd_400x400.jpg"/>


  <meta property="og:title" content="Preprockets Digital Services"/>
  <meta property="og:description" content="Preprockets Digital Services. Apne Business Ko De Ek Nayi Udaan!"/>
  <meta property="og:image" content="https://instagram.flko7-3.fna.fbcdn.net/v/t51.2885-19/435145071_1145834283075755_3259311141175043805_n.jpg?_nc_ht=instagram.flko7-3.fna.fbcdn.net&_nc_cat=111&_nc_ohc=BHsEQt5AztwQ7kNvgH-dP5K&edm=AEhyXUkBAAAA&ccb=7-5&oh=00_AYBbSArwYPew9XKWC6Qv7CqF_UF80nEQ4i6_BCb9AWGWFQ&oe=66984FAD&_nc_sid=8f1549%22"/>
  <meta property="og:url" content="https://www.instagram.com/preprockets/"/>
  <meta property="og:type" content="website"/>

  <meta property="og:title" content="Preprockets Digital Services"/>
  <meta property="og:description" content="Preprockets Digital Services. Apne Business Ko De Ek Nayi Udaan!"/>
  <meta property="og:image" content="https://scontent.flko7-4.fna.fbcdn.net/v/t39.30808-1/434336296_122096362100270093_746723831520368476_n.jpg?stp=dst-jpg_p200x200&_nc_cat=102&ccb=1-7&_nc_sid=f4b9fd&_nc_ohc=otuOfad8qEsQ7kNvgHhGWew&_nc_ht=scontent.flko7-4.fna&oh=00_AYB7Ylrmk9eoP7b0rSODstj7cCILg-BDYc12VEzLm-yc_w&oe=669861CB"/>
  <meta property="og:url" content="https://www.facebook.com/profile.php?id=61558102794628"/>
  <meta property="og:type" content="website"/>

  <meta property="og:title" content="Preprockets Digital Services"/>
  <meta property="og:description" content="Preprockets Digital Services. Apne Business Ko De Ek Nayi Udaan. preprockets.com . +91-921 925 3496"/>
  <meta property="og:image" content="https://yt3.googleusercontent.com/wSI9wtheE1wCAV4RfQnv_Bsr3aMu7KSjoxD_XK_XQCZqVRx_zYQR2eV1sOWMl6CIncOsHleH=s160-c-k-c0x00ffffff-no-rj"/>
  <meta property="og:url" content="https://www.youtube.com/@Preprockets"/>
  <meta property="og:type" content="video.other"/>

  <meta name="geo.position" content="26.8943375;81.0589693"/>
<meta name="geo.placename" content="Preprockets Digital Services | Digital Marketing Agency | Website Design Services | SEO Services | Social Media Marketing"/>
<meta name="geo.region" content="Country Code"/>
        
    
      </Helmet>

    <div className="mt-5" name="about">

      <Container  style={{textAlign:"center",fontFamily:"Montserrat",fontSize: "1.5rem"}} className="mt-5 mb-5"> 
        <span><a href="https://www.preprockets.com/" style={{color:"orange"}}>Home</a> / About Us</span>
      </Container>

      <Container className="body">
        <Row>
          <Col xs={12} md={6}>
            <Card className="aboutus-card">
              <Card.Body>
                <Card.Title className="aboutus-main-heading">
                  <Stack direction="horizontal" gap={2} className="d-flex justify-content-center">
                    <FaBullseye color="orange" />
                    <div>Who we are -</div>
                  </Stack>
                </Card.Title>
                <Card.Text className="aboutus-main-paragraph" style={{textAlign:"start"}}>
                At Preprockets Digital Services, we are your strategic partner in digital success. Our tailored digital marketing solutions drive business growth, enhance online visibility, and maximize ROI. Specializing in Professional Web Design Services, we are recognized as one of the Top Website Design Companies. Our expertise extends to Digital Marketing on Meta, Social Media Marketing, and Google Ads management. We are honored to be listed among the Best Social Media Marketing Agencies in India 2024 and the Best SEO Company in Lucknow and India. Additionally, we rank in the Top 30 Web Design Companies and the 20 Best Google Ads Agencies for July 2024. Empowering businesses to thrive in the digital landscape with innovative strategies, our comprehensive digital solutions ensure sustainable business success.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col xs={12} md={6}>
            <Card className="aboutus-card">
              <Card.Body>
                <Card.Title className="aboutus-our-mission-heading">
                  <Stack direction="horizontal" gap={2} className="d-flex justify-content-center">
                    <FaChartLine color="orange" />
                    <div>Our Mission</div>
                  </Stack>
                </Card.Title>
                <Card.Text className="aboutus-our-mission-paragraph" style={{textAlign:"start"}}>
                At Preprockets Digital Services, our inspiring vision and mission are centered around empowering businesses in the digital age. As a leading digital marketing agency, we are committed to core values and innovative digital strategies. We address challenges such as low local awareness, intense market competition, and the need for effective client acquisition. Our tailored solutions help you attract the right clients, unlock your full potential, and achieve sustainable business success. We offer comprehensive services including website design, website development, SEO, social media management, PPC campaigns, content strategy, branding, digital transformation, customer engagement, marketing analytics, SMS marketing, email marketing, WhatsApp marketing, Google Ads, and Meta Ads.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col xs={12} md={6}>
            <Card className="aboutus-card">
              <Card.Body>
                <Card.Title className="aboutus-data-driven-approach-heading">
                  <Stack direction="horizontal" gap={2} className="d-flex justify-content-center">
                    <FaUsers color="orange" />
                    <div>Data Driven Approach</div>
                  </Stack>
                </Card.Title>
                <Card.Text className="aboutus-data-driven-approach-paragraph" style={{textAlign:"start"}}>
                At Preprockets Digital Services, we harness the power of data from industry leaders like SEMrush, Facebook, Meta, and Google Ads. Our strategies are firmly rooted in advanced data analytics, insights, and continuous optimization. We ensure every decision is driven by actionable data, delivering tangible results, enhancing online visibility, and maximizing ROI.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col xs={12} md={6}>
            <Card className="aboutus-card">
              <Card.Body>
                <Card.Title className="aboutus-client-centric-heading">
                  <Stack direction="horizontal" gap={2} className="d-flex justify-content-center">
                    <FaHandshake color="orange" />
                    <div>Client Centric Focus</div>
                  </Stack>
                </Card.Title>
                <Card.Text className="aboutus-client-centric-paragraph" style={{textAlign:"start"}}>
                At Preprockets Digital Services, we are committed to your success. We prioritize understanding your unique goals, challenges, and vision to tailor innovative solutions aligned with your business objectives. Our goal is to deliver measurable outcomes, leveraging advanced analytics, insights, and continuous optimization to ensure sustainable growth and maximum ROI.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          {/* <Col xs={12} md={6}>
            <Card className="aboutus-card">
              <Card.Body>
                <Card.Title className="aboutus-lets-connect-heading text-center">
                  Let's Connect
                </Card.Title>
                <Card.Text className="aboutus-lets-connect-paragraph">
                  Ready to take your digital presence to new heights? Contact
                  us today for a consultation and discover how{" "}
                  <span>
                    <em>Preprockets Digital Services</em>
                  </span>{" "}
                  can be your catalyst for digital success.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col> */}

          {/* <Col xs={12} md={6}>
              <div className="lets-connect-div">
                <Button className="lets-connect-btn"
                onClick={() =>
                  window.open(
                    `https://api.whatsapp.com/send?phone=+919219253496&text=${encodeURIComponent(
                      whatsappMessage
                    )}`,
                    "_blank"
                  )
                }
                >Let's Connect Now <span><BiPaperPlane className="bi-paper-plane"/></span></Button>
              </div>
          </Col> */}
        </Row>

        {/* WhatsApp Button */}
        <MdWhatsapp
          className="whatsapp-icon"
          onClick={() =>
            window.open(
              `https://api.whatsapp.com/send?phone=+919219253496&text=${encodeURIComponent(
                whatsappMessage
              )}`,
              "_blank"
            )
          }
        />
      </Container>
    </div>
    </div>
  );
};

export default AboutUs;
