// Header.js

import React from "react";
import { Navbar, Nav, Container, Stack } from "react-bootstrap";
import { Link as RouterLink } from "react-router-dom";
import { Image } from "react-bootstrap";
import "./Header.css";

const Header = () => {
    const scrollToSection = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    };

    return (
        <Navbar expand="lg" className="navbar-dark header-bottom-nav-background">
            <Container>
                <Navbar.Brand as={RouterLink} to="/" onClick={() => scrollToSection('home')}>
                    <Stack direction="horizontal" gap={2}>
                    <Image src="https://expensetracker12911.s3.amazonaws.com/Logo+Png-01-fotor-bg-remover-2024040211621.png" className="header-brand-logo" alt="Logo" />
                    <Stack>
                    <div className="header-brand-name">Preprockets</div>
                    <div className="header-tag-line">Apne Business Ko De Nayi Udaan</div>
                    </Stack>
                    </Stack>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ms-auto">
                        <Nav.Link as={RouterLink} to="/" className="nav-link">Home</Nav.Link>
                        <Nav.Link as={RouterLink} to="/about" onClick={() => scrollToSection('about')} className="nav-link">About Us</Nav.Link>
                        <Nav.Link as={RouterLink} to="/services" onClick={() => scrollToSection('services')} className="nav-link">Our Services</Nav.Link>
                        <Nav.Link as={RouterLink} to="/portfolio" onClick={() => scrollToSection('portfolio')} className="nav-link">Portfolio</Nav.Link>
                        <Nav.Link as={RouterLink} to="/contact" onClick={() => scrollToSection('contactus')} className="nav-link">Contact Us</Nav.Link>
                        <Nav.Link as={RouterLink} to="/faq" onClick={() => scrollToSection('faq')} className="nav-link">FAQ's</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default Header;

