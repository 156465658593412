import React, { useState } from "react";
import "./Package.css"; // Import CSS file for styling
import {Card, Button,Container} from 'react-bootstrap';
import ScheduleAppointmentModal from "./ScheduleAppointmentPage ";

const packages = [
    {
        name: "Basic Package - Starter Website",
        features: [
          "Customized design with up to 5 pages",
          "Responsive design for mobile and tablet devices",
          "Contact form integration",
          "Basic SEO optimization",
          "1 round of revisions",
          "Estimated delivery: 2 weeks",
          "High-quality images and graphics",
          "Mobile-friendly navigation menu",
          "Social media sharing buttons",
          "Basic analytics tracking",
          "Email subscription popup",
          "Cookie consent banner",
          "Footer with contact information",
          "Terms and conditions page",
          "Privacy policy page",
          "Basic security measures",
        ],
        price: "Price: ₹35,000",
      },
      {
        name: "Standard Package - Professional Website",
        features: [
          "Customized design with up to 10 pages",
          "Responsive design with cross-browser compatibility",
          "Contact form with advanced features (e.g., file upload)",
          "SEO-friendly URLs and metadata setup",
          "2 rounds of revisions",
          "Google Analytics integration",
          "Estimated delivery: 4 weeks",
          "High-quality images and graphics",
          "Mobile-friendly navigation menu",
          "Social media sharing buttons",
          "Advanced analytics tracking",
          "Email subscription popup with customization",
          "Cookie consent banner with preferences",
          "Footer with contact information and quick links",
          "Terms and conditions page",
          "Privacy policy page with GDPR compliance",
          "Advanced security measures (SSL, firewall, etc.)",
          "Search engine submission and optimization",
          "Custom error pages (404, 500, etc.)",
          "Integration with third-party APIs (e.g., payment gateways)",
          "Content management system for easy updates",
        ],
        price: "Price: ₹60,000"
      },
      {
        name: "Premium Package - E-commerce Website",
        features: [
          "Customized design with up to 15 pages",
          "Responsive design optimized for e-commerce functionality",
          "Product catalog setup (up to 50 products)",
          "Shopping cart and checkout system",
          "Payment gateway integration (e.g., PayPal, Stripe)",
          "Advanced SEO setup with keyword research",
          "3 rounds of revisions",
          "Estimated delivery: 6 weeks",
          "High-quality product images and zoom functionality",
          "Product comparison feature",
          "Wishlist and favorites functionality",
          "Advanced search filters (price range, category, etc.)",
          "Customer reviews and ratings system",
          "Related products suggestions",
          "Inventory management and tracking",
          "Discounts and promotions setup",
          "Order tracking and shipment notifications",
          "User account dashboard with order history",
          "Multi-currency support",
          "Multi-language support",
          "Social media integration for product sharing",
          "Secure checkout process (SSL, PCI compliance, etc.)",
          "Return and refund management system",
          "Automated email notifications for orders",
          "Live chat support for customer assistance",
          "Integration with email marketing tools",
          "Advanced analytics and reporting",
          "Customizable email templates for notifications",
          "Automatic tax calculation based on location",
          "Cross-selling and upselling features",
          "Integration with third-party APIs for shipping and logistics",
        ],
        price: "Price: 1,10,000"
      },
      {
        name: "School Website Package",
        features: [
          "Customized design with up to 10 pages",
          "Responsive design for school-related content and features",
          "Student admission form integration",
          "Events calendar setup",
          "Teachers' directory page",
          "Curriculum and courses section",
          "Photo gallery for school events and activities",
          "News and announcements section",
          "Parent-teacher communication portal",
          "Online school newsletter system",
          "Student and parent portals for resources and information",
          "Integration with learning management systems (LMS)",
          "Virtual classroom setup for remote learning",
          "School blog for articles and updates",
          "Integration with social media platforms",
          "School achievements showcase",
          "Interactive campus map",
          "Online library catalog and book reservation",
          "Career counseling and guidance section",
          "Volunteer and alumni network platform",
          "School safety and emergency information",
          "Accessible design for users with disabilities",
          "Event registration and ticketing system",
          "Customizable school branding and logos",
          "Data analytics dashboard for administrators",
          "Automated attendance tracking system",
          "Parent-teacher meeting scheduler",
        ],
        price: "Price: ₹1,50,000",
      },
      {
        name: "Hospital Website Package",
        features: [
          "Customized design with up to 15 pages",
          "Responsive design tailored for healthcare services",
          "Appointment booking system",
          "Patient portal integration",
          "Medical services information pages",
          "Doctor profiles and specialties showcase",
          "Online patient registration forms",
          "Health insurance information section",
          "Telemedicine and virtual consultation setup",
          "Health blog for medical articles and tips",
          "Patient testimonials and success stories",
          "Medical news and updates section",
          "Pharmacy and medication ordering portal",
          "Medical research and publications repository",
          "Emergency services and contact information",
          "Virtual tours of hospital facilities",
          "Health and wellness resources library",
          "Hospital events and community outreach programs",
          "Mobile app integration for patient convenience",
          "Healthcare career opportunities page",
          "Interactive health risk assessment tools",
          "Patient support groups and forums",
          "Hospital sustainability and eco-friendly initiatives",
          "Multilingual support for diverse patients",
          "Online bill payment and financial assistance information",
          "Medical staff training and education resources",
          "Emergency preparedness guidelines",
          "Patient privacy and data security measures",
          "Customizable patient experience surveys",
          "Integration with medical devices and wearables",
          "Hospital accreditation and awards showcase",
          "Accessibility features for patients with disabilities",
          "Automated appointment reminders and notifications",
          "Video consultations and telehealth services",
          "Integration with electronic health records (EHR)",
          "Medical research collaboration platform",
        ],
        price: "Price: ₹5,60,000",
      },
      {
        name: "Small Clinic Website Package",
        features: [
          "Customized design with up to 7 pages",
          "Responsive design for clinic services and information",
          "Online appointment scheduling",
          "Patient education resources",
          "Staff profiles and specialties showcase",
          "Clinic services and treatment options overview",
          "Health blog for medical articles and tips",
          "Patient testimonials and success stories",
          "Virtual tours of clinic facilities",
          "Health and wellness resources library",
          "Clinic events and community health programs",
          "Mobile app integration for patient convenience",
          "Interactive symptom checker and health assessment tools",
          "Patient support groups and forums",
          "Clinic sustainability and eco-friendly initiatives",
          "Multilingual support for diverse patients",
          "Online bill payment and financial assistance information",
          "Staff training and education resources",
          "Patient privacy and data security measures",
          "Customizable patient experience surveys",
          "Integration with medical devices and wearables",
        ],
        price: "Price: ₹40,000",
      },
      {
        name: "Cloud Kitchen Website Package",
        features: [
          "Customized design with up to 8 pages",
          "Responsive design for food delivery services",
          "Online menu and ordering system",
          "Integration with food delivery platforms (e.g., Zomato, Swiggy)",
          "Real-time order tracking",
          "Menu customization and meal customization options",
          "Special offers and discounts management",
          "Customer reviews and ratings system",
          "Secure online payments with multiple payment gateways",
          "Order history and reordering functionality",
          "Multi-location support for chain kitchens",
          "Ingredient sourcing and sustainability information",
          "Delivery radius and availability settings",
          "Promotion and marketing campaign integration",
          "Inventory management for ingredients and supplies",
          "Staff management and scheduling tools",
          "Customer support chatbot or live chat feature",
          "Analytics dashboard for sales and performance metrics",
          "Integration with loyalty programs and rewards",
          "Customizable packaging and branding options",
          "Compliance with food safety regulations and certifications",
        ],
        price: "Price: ₹45,000",
      },
  ];
  
  const PackageCard = ({ name, features,price }) => {

    const [showAppointmentModal,setShowAppointmentModal] = useState(false);
    const [show,setShow] = useState(true);

    const handleClose = () =>{
      setShowAppointmentModal(!showAppointmentModal)
    }

    const handleShowAppointmentModal = () => {
      setShowAppointmentModal(!showAppointmentModal)
    }


    return (
    <Card className="package-card">
      <Card.Body className="card-body-main">
        <Card.Title className="card-title-main">{name}</Card.Title>

          <ul className="mt-5">
            {features.map((feature, index) => (
              <li key={index}>{feature}</li>
            ))}
          </ul>
          {/* <h2 className="price mt-5">{price}</h2> */}

        <div className="package-btn-div">
        <Button className="package-btn" onClick={handleShowAppointmentModal }>Know More</Button>
        {showAppointmentModal && <ScheduleAppointmentModal show={show} handleClose={handleClose}/>}
        </div>
    
      </Card.Body>
    </Card>
  
    );
  };
  
  const Packages = () => (
    <div className="packages-container">
      {packages.map((pack, index) => (
        <PackageCard key={index} {...pack} />
      ))}
    </div>
  );
  
  export default Packages;