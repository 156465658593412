import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { BiLockAlt, BiUser, BiWorld, BiShield, BiMessageRoundedEdit } from 'react-icons/bi';

const TermsOfUse = () => {
  return (
    <Container className="py-5" style={{fontFamily:"Montserrat"}}>
      <h1 className="text-center mb-5">Terms of Use</h1>
      <Row className="mb-4">
        <Col md={10} className="mx-auto">
          <h2>Introduction and Acceptance of Terms</h2>
          <p>Welcome to Preprockets Digital Services By accessing and using this website, you agree to comply with and be bound by the following terms and conditions of use. If you disagree with any part of these terms, please do not use our website.</p>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col md={10} className="mx-auto">
          <h2>User Eligibility</h2>
          <p>Users must be at least 18 years old to use our services. By using this website, you represent and warrant that you meet the eligibility criteria.</p>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col md={10} className="mx-auto">
          <h2>User Account and Responsibilities</h2>
          <p>To access certain features of our website, you may need to create an account. You are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer or device.</p>
          <p>You agree to provide accurate and complete information when creating an account and to update your information promptly if there are any changes.</p>
          <p>You are solely responsible for all activities that occur under your account, and you agree to notify us immediately of any unauthorized use of your account or any other breach of security.</p>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col md={10} className="mx-auto">
          <h2>Use of Website and Services</h2>
          <p>You agree to use our website and services only for lawful purposes and in a manner that does not infringe upon the rights of others or restrict or inhibit their use of the website.</p>
          <p>You may not use our website for any illegal or unauthorized purpose, including but not limited to transmitting any harmful code or engaging in any activity that disrupts or interferes with the operation of the website or its services.</p>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col md={10} className="mx-auto">
          <h2>Intellectual Property Rights</h2>
          <p>All content and materials on this website, including but not limited to text, graphics, logos, images, and software, are the property of Preprockets Digital Services and are protected by intellectual property laws. You may not use, reproduce, or distribute any content without our express written permission.</p>
          <p>You may use our website and services for your personal and non-commercial use only. Any other use, including but not limited to commercial use, modification, distribution, or reproduction of our content, requires our prior written consent.</p>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col md={10} className="mx-auto">
          <h2>Privacy Policy and Data Protection</h2>
          <p>Our Privacy Policy governs the collection, use, and disclosure of personal information we receive from users of our website. By using our website, you consent to the terms of our Privacy Policy.</p>
          <p>We may collect and use your personal information to provide and improve our services, communicate with you, and customize your experience on our website. We will not sell or share your information with third parties except as outlined in our Privacy Policy.</p>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col md={10} className="mx-auto">
          <h2>Limitation of Liability</h2>
          <p>We strive to provide accurate and up-to-date information on our website, but we do not warrant the completeness, reliability, or accuracy of this information. We shall not be liable for any damages arising from the use of or inability to use our website, including but not limited to direct, indirect, incidental, consequential, or punitive damages.</p>
          <p>In no event shall Preprockets Digital Services, its directors, employees, or agents be liable to you for any claim, demand, or damages of any kind arising out of or in connection with your use of the website or its services.</p>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col md={10} className="mx-auto">
          <h2>Dispute Resolution and Governing Law</h2>
          <p>Any disputes arising from your use of our website shall be resolved through arbitration in Lucknow, Uttar Pradesh, in accordance with the laws of India.</p>
          <p>These Terms of Use shall be governed by and construed in accordance with the laws of India, without regard to its conflict of law provisions.</p>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col md={10} className="mx-auto">
          <h2>Updates and Modifications</h2>
          <p>We reserve the right to update or modify these Terms of Use at any time without prior notice. Continued use of the website after any such changes constitutes your acceptance of the revised terms.</p>
          <p>It is your responsibility to review these Terms of Use periodically for changes. If you do not agree to any revised terms, you must stop using the website immediately.</p>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col md={10} className="mx-auto">
        <h2>Contact</h2>
          <p>By using our website, you agree to these Terms of Use. If you have any questions or concerns about these terms, please contact us at - </p>
          <div>
                <p>Preprockets Digital Services</p>
                <p>Email: preprockets@gmail.com</p>
                <p>Phone: +91-9219253496</p>
                <p>Address: Start Heights Apartments Near BBD University Lucknow</p>
              </div>
          <p>Last Updated: 15-05-2024</p>
        </Col>
      </Row>
    </Container>
  );
}

export default TermsOfUse;
