import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { BiLockAlt, BiUser, BiEnvelope, BiPhoneCall, BiCreditCard } from 'react-icons/bi';

const PrivacyPolicy = () => {
  return (
    <Container className="py-5" style={{fontFamily:"Montserrat"}}>
      <h1 className="text-center mb-5">Privacy Policy</h1>
      <Row className="mb-4">
        <Col md={10} className="mx-auto">
          <h2>Information Collection</h2>
          <ul>
            <li><BiUser className="mr-2"/> Names</li>
            <li><BiEnvelope className="mr-2"/> Email addresses</li>
            <li><BiLockAlt className="mr-2"/> Mailing addresses</li>
            <li><BiPhoneCall className="mr-2"/> Phone numbers</li>
            <li><BiCreditCard className="mr-2"/> Payment information (such as credit card details)</li>
            <li>Any other information that users voluntarily provide through forms, surveys, or interactions with our website.</li>
          </ul>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col md={10} className="mx-auto">
          <h2>Purpose of Information Collection</h2>
          <p>We collect this information for the following purposes:</p>
          <ul>
            <li>To improve customer service by responding to user inquiries and support needs more efficiently.</li>
            <li>To process payments securely and provide services or products ordered by users.</li>
            <li>To send newsletters, promotional materials, and updates about our products or services, provided users have opted in to receive such communications.</li>
            <li>To personalize user experience and tailor our website content and offerings based on user preferences.</li>
            <li>To comply with legal requirements and respond to lawful requests from authorities.</li>
          </ul>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col md={10} className="mx-auto">
          <h2>Data Protection Measures</h2>
          <p>We take appropriate measures to protect users' personal information:</p>
          <ul>
            <li>We use encryption protocols (such as SSL) to secure sensitive data during transmission.</li>
            <li>User information is stored on secure servers with limited access granted only to authorized personnel who need the information to perform their duties.</li>
            <li>We conduct regular security audits, updates, and training to safeguard against unauthorized access, disclosure, alteration, or destruction of user data.</li>
          </ul>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col md={10} className="mx-auto">
          <h2>Sharing of Personal Information</h2>
          <p>We do not sell, trade, or rent users' personal information to third parties for marketing purposes. However, we may share personal information in the following circumstances:</p>
          <ul>
            <li>With service providers who assist us in operating our website, processing payments, or conducting business operations (e.g., shipping companies for order fulfillment).</li>
            <li>When required by law or in response to valid legal requests, such as court orders or government regulations.</li>
            <li>To protect the rights, property, or safety of Preprockets Digital Services, our users, or others.</li>
          </ul>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col md={10} className="mx-auto">
          <h2>User Rights</h2>
          <p>Users have certain rights regarding their personal information:</p>
          <ul>
            <li>The right to access their personal information and request details about how it is being used.</li>
            <li>The right to correct or update their personal information if it is inaccurate or incomplete.</li>
            <li>The right to request the deletion of their personal information under certain circumstances, such as when it is no longer necessary for the purposes for which it was collected.</li>
            <li>The right to object to the processing of their personal information or withdraw consent for specific uses, where applicable.</li>
          </ul>
          <p>Users can exercise these rights by contacting us using the information provided in the "Contact Us" section below. We will respond to user requests within a reasonable timeframe, as required by applicable laws and regulations.</p>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col md={10} className="mx-auto">
          <h2>Changes to This Privacy Policy</h2>
          <p>We reserve the right to update or modify this privacy policy at any time. Any changes will be reflected with a revised "Last Updated" date at the bottom of this page. Users are encouraged to review this policy periodically to stay informed about how we protect and use their personal information.</p>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col md={10} className="mx-auto">
          <h2>Contact Us</h2>
          <p>If you have any questions, concerns, or requests regarding this privacy policy or our handling of your personal information, please contact us at -</p>
          <div>
                <p>Preprockets Digital Services</p>
                <p>Email: preprockets@gmail.com</p>
                <p>Phone: +91-9219253496</p>
                <p>Address: Start Heights Apartments Near BBD University Lucknow</p>
              </div>
              <p>Last Updated: 15-05-2024</p>
        </Col>
      </Row>
    </Container>
  );
}

export default PrivacyPolicy;
