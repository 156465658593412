import React from 'react';
import { Container, Row, Col, Image,Table } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { MdWhatsapp } from 'react-icons/md';
import './Faq.css'; // Import CSS file

const faqs = [
    {
      question: 'What is digital marketing, and why is it important for businesses?',
      answer: 'Digital marketing refers to promoting products or services using digital channels such as websites, social media, email, and search engines. It is crucial for businesses as it allows them to reach a wider audience, target specific demographics, track performance metrics, and achieve measurable results compared to traditional marketing methods.',
    },
    {
      question: 'What are the key components of a successful digital marketing strategy?',
      answer: 'A successful digital marketing strategy includes elements such as search engine optimization (SEO), content marketing, social media marketing, email marketing, pay-per-click (PPC) advertising, and analytics. These components work together to increase brand visibility, drive traffic, generate leads, and boost conversions.',
    },
    {
      question: 'How can digital marketing help my business attract more customers?',
      answer: 'Digital marketing can attract more customers by improving brand awareness through targeted advertising, engaging with potential customers on social media platforms, optimizing website content for search engines to increase organic traffic, and leveraging email campaigns to nurture leads and encourage repeat business.',
    },
    {
      question: 'What role does content play in digital marketing?',
      answer: 'Content is a critical aspect of digital marketing as it provides valuable information to customers, establishes credibility and authority in the industry, improves search engine rankings through optimized keywords, and encourages audience engagement and sharing on social media platforms.',
    },
    {
      question: 'How can I measure the success of my digital marketing efforts?',
      answer: 'You can measure the success of your digital marketing efforts using key performance indicators (KPIs) such as website traffic, conversion rates, click-through rates (CTR), engagement metrics on social media, email open rates and click rates, return on investment (ROI) from advertising campaigns, and customer lifetime value (CLV).',
    },
    {
      question: 'What are some common digital marketing mistakes to avoid?',
      answer: 'Common digital marketing mistakes to avoid include neglecting mobile optimization, ignoring data analytics and insights, using irrelevant or excessive keywords for SEO, not having a clear call-to-action (CTA) in marketing campaigns, neglecting social media engagement with customers, and failing to adapt to changing trends and technologies.',
    },
    {
      question: 'How can I stay updated with the latest digital marketing trends and best practices?',
      answer: 'To stay updated, you can follow industry blogs, attend webinars and conferences, join digital marketing communities and forums, subscribe to newsletters and publications from reputable sources, participate in online courses and certifications, and network with other professionals in the field.',
    },
  ];
  

  const FAQ = () => {

    const mapName = 'Preprockets Digital Services'; // Name of the map location
    const mapDescription = 'Google Maps showing Preprockets Digital Services'; // Description of the map
    const mapLatitude = '26.890708076660296'; // Latitude of the map location
    const mapLongitude = '81.05525187522358'; // Longitude of the map location
    const embedUrl = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3558.402821572815!2d81.05525187522358!3d26.890708076660296!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2ce531f503ce4a25%3A0x57166aee9b70844!2sPreprockets%20Digital%20Services!5e0!3m2!1sen!2sin!4v1712502768391!5m2!1sen!2sin'; // URL of embedded map

    const whatsappMessage = "Hello, I would like to connect with you regarding digital services.";
    return (

      <div>

<Helmet>
        <title>Faq - Preprockets Digital Services</title>
        <meta name="description" content="Explore frequently asked questions about Preprockets Digital Services. Discover how we excel in digital marketing with strategic solutions that drive growth, enhance online visibility, and maximize ROI. Empower your business to thrive in the digital age with our tailored approaches and innovative strategies for sustainable success." />
        <meta name="keywords" content="digital marketing services, strategic solutions, business growth strategies, enhance online visibility, maximize ROI, innovative digital strategies, sustainable business success, SEO services, social media marketing, PPC campaigns, web development, content strategy, brand visibility, digital transformation, customer engagement, marketing analytics" />
        <meta property="og:type" content="organization" />
        <meta property="og:url" content="https://www.preprockets.com" />
        <meta property="og:title" content="Preprockets Digital Services" />
        <meta property="og:description" content="At Preprockets Digital Services, we go beyond traditional digital marketing. We're your strategic partner in navigating the dynamic digital landscape, delivering tailored solutions that drive growth, enhance visibility, and maximize ROI. Our mission is clear: empower businesses to thrive in the digital age by unlocking their full potential and achieving sustainable success through innovative strategies." />
        <meta property="og:image" content="https://expensetracker12911.s3.amazonaws.com/Logo+Png-01-fotor-bg-remover-2024040211621.png" />
       
        <meta name="twitter:site" content="@Preprockets"/>
<meta name="twitter:title" content="Preprockets Digital Services"/>
<meta name="twitter:description" content="Preprockets Digital Services: Your trusted partner in digital success."/>
<meta name="twitter:image" content="https://pbs.twimg.com/profile_images/1775109624561356800/V9TrS4pd_400x400.jpg"/>


  <meta property="og:title" content="Preprockets Digital Services"/>
  <meta property="og:description" content="Preprockets Digital Services. Apne Business Ko De Ek Nayi Udaan!"/>
  <meta property="og:image" content="https://instagram.flko7-3.fna.fbcdn.net/v/t51.2885-19/435145071_1145834283075755_3259311141175043805_n.jpg?_nc_ht=instagram.flko7-3.fna.fbcdn.net&_nc_cat=111&_nc_ohc=BHsEQt5AztwQ7kNvgH-dP5K&edm=AEhyXUkBAAAA&ccb=7-5&oh=00_AYBbSArwYPew9XKWC6Qv7CqF_UF80nEQ4i6_BCb9AWGWFQ&oe=66984FAD&_nc_sid=8f1549%22"/>
  <meta property="og:url" content="https://www.instagram.com/preprockets/"/>
  <meta property="og:type" content="website"/>

  <meta property="og:title" content="Preprockets Digital Services"/>
  <meta property="og:description" content="Preprockets Digital Services. Apne Business Ko De Ek Nayi Udaan!"/>
  <meta property="og:image" content="https://scontent.flko7-4.fna.fbcdn.net/v/t39.30808-1/434336296_122096362100270093_746723831520368476_n.jpg?stp=dst-jpg_p200x200&_nc_cat=102&ccb=1-7&_nc_sid=f4b9fd&_nc_ohc=otuOfad8qEsQ7kNvgHhGWew&_nc_ht=scontent.flko7-4.fna&oh=00_AYB7Ylrmk9eoP7b0rSODstj7cCILg-BDYc12VEzLm-yc_w&oe=669861CB"/>
  <meta property="og:url" content="https://www.facebook.com/profile.php?id=61558102794628"/>
  <meta property="og:type" content="website"/>

  <meta property="og:title" content="Preprockets Digital Services"/>
  <meta property="og:description" content="Preprockets Digital Services. Apne Business Ko De Ek Nayi Udaan. preprockets.com . +91-921 925 3496"/>
  <meta property="og:image" content="https://yt3.googleusercontent.com/wSI9wtheE1wCAV4RfQnv_Bsr3aMu7KSjoxD_XK_XQCZqVRx_zYQR2eV1sOWMl6CIncOsHleH=s160-c-k-c0x00ffffff-no-rj"/>
  <meta property="og:url" content="https://www.youtube.com/@Preprockets"/>
  <meta property="og:type" content="video.other"/>

  <meta name="geo.position" content="26.8943375;81.0589693"/>
<meta name="geo.placename" content="Preprockets Digital Services | Digital Marketing Agency | Website Design Services | SEO Services | Social Media Marketing"/>
<meta name="geo.region" content="Country Code"/>
        
      
      </Helmet>
    
      <Container className="mt-5" name="faq">

      <Container  style={{textAlign:"center",fontFamily:"Montserrat",fontSize: "1.5rem"}}> 
        <span ><a href="https://www.preprockets.com/" style={{color:"orange"}}>Home</a> / Faq</span>
      </Container>

        <Row>
          <Col md={12} className='d-grid justify-content-center align-items-center mb-5'>
            <Image src="https://expensetracker12911.s3.amazonaws.com/faq.jpg" className='faq-image' fluid /> 
          </Col>
          <Col md={12}>
            <Table striped bordered hover responsive className='table-box-shadow'>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Question</th>
                  <th>Answer</th>
                </tr>
              </thead>
              <tbody>
                {faqs.map((faq, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td className='faq-question'>{faq.question}</td>
                    <td className='faq-answer'>{faq.answer}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
      <MdWhatsapp
          className="whatsapp-icon"
          onClick={() =>
            window.open(
              `https://api.whatsapp.com/send?phone=+919219253496&text=${encodeURIComponent(
                whatsappMessage
              )}`,
              "_blank"
            )
          }
        />
      </div>
    );
  };


export default FAQ;
