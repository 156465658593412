import React from "react";
import { Container, Row, Col,Button, Carousel, Image } from "react-bootstrap";
import { FaExternalLinkAlt } from "react-icons/fa";
import { Helmet } from "react-helmet";
import { MdWhatsapp } from "react-icons/md";
import "./Portfolio.css";

const Portfolio = () => {

  
  const mapName = 'Preprockets Digital Services'; // Name of the map location
  const mapDescription = 'Google Maps showing Preprockets Digital Services'; // Description of the map
  const mapLatitude = '26.890708076660296'; // Latitude of the map location
  const mapLongitude = '81.05525187522358'; // Longitude of the map location
  const embedUrl = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3558.402821572815!2d81.05525187522358!3d26.890708076660296!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2ce531f503ce4a25%3A0x57166aee9b70844!2sPreprockets%20Digital%20Services!5e0!3m2!1sen!2sin!4v1712502768391!5m2!1sen!2sin'; // URL of embedded map


  const whatsappMessage = "Hello, I would like to connect with you regarding digital services.";

  const projects = [
    {
      id: 1,
      title: "E-commerce Website",
      description: "Built a modern e-commerce website with seamless checkout process.",
      image: "https://via.placeholder.com/800x600.png?text=Project+1",
      link: "https://example.com/project1",
    },
    {
      id: 2,
      title: "Mobile App Development",
      description: "Developed a feature-rich mobile app for iOS and Android platforms.",
      image: "https://via.placeholder.com/800x600.png?text=Project+2",
      link: "https://example.com/project2",
    },
    {
      id: 3,
      title: "Digital Marketing Campaign",
      description: "Executed a successful digital marketing campaign with impressive ROI.",
      image: "https://via.placeholder.com/800x600.png?text=Project+3",
      link: "https://example.com/project3",
    },
  ];

  return (

    <div>

<Helmet>
        <title>Portfolio - Preprockets Digital Services</title>
        <meta name="description" content="At Preprockets Digital Services, we go beyond traditional digital marketing. We're your strategic partner in navigating the dynamic digital landscape, delivering tailored solutions that drive growth, enhance visibility, and maximize ROI. Our mission is clear: empower businesses to thrive in the digital age by unlocking their full potential and achieving sustainable success through innovative strategies." />
        <meta name="keywords" content="Digital marketing agency, Strategic digital solutions, Business growth strategies, Enhanced online visibility, Maximize ROI, Innovative digital strategies, Sustainable business success, Navigating digital landscape, Targeted client acquisition, Unlock business potential, Top digital marketing agency, Global SEO services, International online visibility, Global business growth strategies, Worldwide client acquisition, Leading digital solutions provider, Global market competition, International SEO expertise, Global digital landscape navigation, Maximize ROI globally, Best digital marketing agency in India, SEO services in Lucknow, Digital marketing solutions in Uttar Pradesh, Top SEO company in Lucknow, Online visibility in India, Business growth strategies in Lucknow, Local client acquisition in Uttar Pradesh, Leading digital solutions provider in Lucknow, Digital marketing trends in India, SEO techniques for Indian businesses" />
        <meta property="og:type" content="organization" />
        <meta property="og:url" content="https://www.preprockets.com" />
        <meta property="og:title" content="Preprockets Digital Services" />
        <meta property="og:description" content="At Preprockets Digital Services, we go beyond traditional digital marketing. We're your strategic partner in navigating the dynamic digital landscape, delivering tailored solutions that drive growth, enhance visibility, and maximize ROI. Our mission is clear: empower businesses to thrive in the digital age by unlocking their full potential and achieving sustainable success through innovative strategies." />
        <meta property="og:image" content="https://expensetracker12911.s3.amazonaws.com/Logo+Png-01-fotor-bg-remover-2024040211621.png" />
      
        <meta name="twitter:site" content="@Preprockets"/>
<meta name="twitter:title" content="Preprockets Digital Services"/>
<meta name="twitter:description" content="Preprockets Digital Services: Your trusted partner in digital success."/>
<meta name="twitter:image" content="https://pbs.twimg.com/profile_images/1775109624561356800/V9TrS4pd_400x400.jpg"/>


  <meta property="og:title" content="Preprockets Digital Services"/>
  <meta property="og:description" content="Preprockets Digital Services. Apne Business Ko De Ek Nayi Udaan!"/>
  <meta property="og:image" content="https://instagram.flko7-3.fna.fbcdn.net/v/t51.2885-19/435145071_1145834283075755_3259311141175043805_n.jpg?_nc_ht=instagram.flko7-3.fna.fbcdn.net&_nc_cat=111&_nc_ohc=BHsEQt5AztwQ7kNvgH-dP5K&edm=AEhyXUkBAAAA&ccb=7-5&oh=00_AYBbSArwYPew9XKWC6Qv7CqF_UF80nEQ4i6_BCb9AWGWFQ&oe=66984FAD&_nc_sid=8f1549%22"/>
  <meta property="og:url" content="https://www.instagram.com/preprockets/"/>
  <meta property="og:type" content="website"/>

  <meta property="og:title" content="Preprockets Digital Services"/>
  <meta property="og:description" content="Preprockets Digital Services. Apne Business Ko De Ek Nayi Udaan!"/>
  <meta property="og:image" content="https://scontent.flko7-4.fna.fbcdn.net/v/t39.30808-1/434336296_122096362100270093_746723831520368476_n.jpg?stp=dst-jpg_p200x200&_nc_cat=102&ccb=1-7&_nc_sid=f4b9fd&_nc_ohc=otuOfad8qEsQ7kNvgHhGWew&_nc_ht=scontent.flko7-4.fna&oh=00_AYB7Ylrmk9eoP7b0rSODstj7cCILg-BDYc12VEzLm-yc_w&oe=669861CB"/>
  <meta property="og:url" content="https://www.facebook.com/profile.php?id=61558102794628"/>
  <meta property="og:type" content="website"/>

  <meta property="og:title" content="Preprockets Digital Services"/>
  <meta property="og:description" content="Preprockets Digital Services. Apne Business Ko De Ek Nayi Udaan. preprockets.com . +91-921 925 3496"/>
  <meta property="og:image" content="https://yt3.googleusercontent.com/wSI9wtheE1wCAV4RfQnv_Bsr3aMu7KSjoxD_XK_XQCZqVRx_zYQR2eV1sOWMl6CIncOsHleH=s160-c-k-c0x00ffffff-no-rj"/>
  <meta property="og:url" content="https://www.youtube.com/@Preprockets"/>
  <meta property="og:type" content="video.other"/>

  <meta name="geo.position" content="26.8943375;81.0589693"/>
<meta name="geo.placename" content="Preprockets Digital Services | Digital Marketing Agency | Website Design Services | SEO Services | Social Media Marketing"/>
<meta name="geo.region" content="Country Code"/>
        
       
      </Helmet>

    <Container className="portfolio-container mt-5" name="portfolio">

      
    <Container  style={{textAlign:"center",fontFamily:"Montserrat",fontSize: "1.5rem"}}> 
        <span><a href="https://www.preprockets.com/" style={{color:"orange"}}>Home</a> / Portfolio</span>
      </Container>

      <Row>
      <Col md={6} className="d-flex justify-content-center align-items-center mt-1">
          <Image src="https://expensetracker12911.s3.amazonaws.com/2333024.jpg" roundedCircle fluid />
        </Col>
        <Col md={6} className="d-flex justify-content-center align-items-center mt-1">
          <Carousel>
            {projects.map((project) => (
              <Carousel.Item key={project.id}>
                <img className="d-block w-100" src={project.image} alt={project.title} />
                <Carousel.Caption>
                  <h3>{project.title}</h3>
                  <p>{project.description}</p>
                  <Button variant="primary" href={project.link} target="_blank" rel="noopener noreferrer">
                    View Project <FaExternalLinkAlt className="external-link-icon" />
                  </Button>
                </Carousel.Caption>
              </Carousel.Item>
            ))}
          </Carousel>
        </Col>

      </Row>
    </Container>
    <MdWhatsapp
          className="whatsapp-icon"
          onClick={() =>
            window.open(
              `https://api.whatsapp.com/send?phone=+919219253496&text=${encodeURIComponent(
                whatsappMessage
              )}`,
              "_blank"
            )
          }
        />
    </div>
  );
};

export default Portfolio;
