import React from 'react';
import './Map.css'; // Import the CSS file
import { Container } from 'react-bootstrap';


const GoogleMapsComponent = () => {
 
  const embedUrl = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3558.402821572815!2d81.05525187522358!3d26.890708076660296!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2ce531f503ce4a25%3A0x57166aee9b70844!2sPreprockets%20Digital%20Services!5e0!3m2!1sen!2sin!4v1712502768391!5m2!1sen!2sin'; // URL of embedded map

  return (
    <Container>
      <div className="google-maps-container">
        <iframe
          className="google-maps-iframe"
          src={embedUrl}
          allowFullScreen
          title="Google Maps"
        ></iframe>
      </div>
    </Container>
  );
};

export default GoogleMapsComponent;

