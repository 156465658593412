import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { BsCalendar } from 'react-icons/bs';
import "./ScheduleAppointmentPage.css";

const availableSlots = [
    '10:00 AM - 11:00 AM',
    '11:00 AM - 12:00 PM',
    '12:00 PM - 01:00 PM',
    '01:00 PM - 02:00 PM',
    '02:00 PM - 03:00 PM',
    '03:00 PM - 04:00 PM',
    '04:00 PM - 05:00 PM',
    '05:00 PM - 06:00 PM',
    '06:00 PM - 07:00 PM',
    '07:00 PM - 08:00 PM',
    '08:00 PM - 09:00 PM',
];


const ScheduleAppointmentModal = ({ show, handleClose }) => {
    const [appointmentDate, setAppointmentDate] = useState('');
    const [selectedSlot, setSelectedSlot] = useState('');
    const [userName, setUserName] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');
    const [message, setMessage] = useState('');

    const handleScheduleAppointment = () => {
        // Implement your appointment scheduling logic here
        console.log('Appointment scheduled for:', appointmentDate, 'at', selectedSlot);
        console.log('User:', userName);
        console.log('Mobile Number:', mobileNumber);
        console.log('Message:', message);
        handleClose(); // Close the modal after scheduling appointment
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title className='modal-txt'>Know More Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="userName">
                        <Form.Label className='modal-txt'>Your Name</Form.Label>
                        <Form.Control
                            type="text"
                            value={userName}
                            onChange={(e) => setUserName(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group controlId="mobileNumber">
                        <Form.Label className='modal-txt'>Mobile Number</Form.Label>
                        <Form.Control
                            type="tel"
                            value={mobileNumber}
                            onChange={(e) => setMobileNumber(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group controlId="message">
                        <Form.Label className='modal-txt'>Message</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group controlId="appointmentDate">
                        <Form.Label className='modal-txt'>Date</Form.Label>
                        <Form.Control
                            type="date"
                            value={appointmentDate}
                            onChange={(e) => setAppointmentDate(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group controlId="appointmentTime">
                        <Form.Label className='modal-txt'>Time</Form.Label>
                        <Form.Control as="select" value={selectedSlot} onChange={(e) => setSelectedSlot(e.target.value)}>
                            <option value="">Select Time Slot</option>
                            {availableSlots.map((slot, index) => (
                                <option key={index} value={slot}>
                                    {slot}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose} className='close-btn'>
                    Close
                </Button>
                <Button variant="primary" onClick={handleScheduleAppointment} disabled={!appointmentDate || !selectedSlot} className='schedule-btn'>
                    <BsCalendar /> Schedule Appointment
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ScheduleAppointmentModal;
