import React from 'react';
import { Container, Row, Col, Button, Navbar,Stack} from 'react-bootstrap';
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';
import {LiaPaperPlaneSolid} from "react-icons/lia"
import { MdKeyboardArrowUp } from 'react-icons/md';
import { BsDownload } from 'react-icons/bs';
import './Footer.css'; // Import CSS file for custom styles


const Footer = () => {

  
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const whatsappMessage = "Hello, I would like to connect with you regarding digital services.";

  return (

    <div >
      <Navbar className="navbar-footer p-2" expand="lg"  style={{ backgroundImage: `url("https://expensetracker12911.s3.amazonaws.com/orangebackdrop.jpg")` }}>
        <Container className='p-5'>
          <Row style={{width:"100vw"}} className='d-flex justify-content-center align-items-center m-1 p-2'>
            <Col xs={12} md={4} className='m-1'>
              <div className="footer-info">
                <h4>Preprockets Digital Services</h4>
                <p>Email: preprockets@gmail.com</p>
                <p>Phone: +91-9219253496</p>
                <p>Address: Start Heights Apartments Near BBD University Lucknow</p>
              </div>
            </Col>

            <Col xs={12} md={4} className='d-flex justify-content-center align-items-center m-1'>
              <Stack gap={2}>
              <Button className='brochure-btn' >Download Brochure <span className='download-icon'><BsDownload/></span></Button>
              <Button className='brochure-btn'
                    onClick={() =>
                      window.open(
                        `https://api.whatsapp.com/send?phone=+919219253496&text=${encodeURIComponent(
                          whatsappMessage
                        )}`,
                        "_blank"
                      )
                    }
              >Send Message Now <span className='download-icon' 
              ><LiaPaperPlaneSolid/></span></Button>
              </Stack>
            </Col>

            <Col md={3} xs={12} className="d-flex justify-content-center align-items-center m-1">
              <div className="social-icons">
                <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer"><FaFacebook /></a>
                <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer"><FaTwitter /></a>
                <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
                <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer"><FaLinkedin /></a>
              </div>
            </Col>
          </Row>
        </Container>
      </Navbar>
      <Navbar className='bottom-navbar p-2' expand="lg">
        <Container>
            <Row className="d-flex justify-content-center align-items-center m-1 w-100">
            <Col className="copy-right m-1" md={12}>
              <Stack>
                <p className='m-1'>&copy; 2024 Preprockets Digital Services. All Rights Reserved.</p>
                <a href='/privacy-policy' style={{color:"white"}} className='m-1'>Privacy Policy</a>
                <a href='/terms-of-use' style={{color:"white"}} className='m-1'>Terms of use</a>
              </Stack>
            </Col>
            <Col onClick={scrollToTop} md={12} className='d-flex justify-content-center align-items-center m-2'>
              <MdKeyboardArrowUp className="scroll-to-top" />
            </Col>
          </Row>
          </Container>
      </Navbar>
      </div>
  );
};

export default Footer;
