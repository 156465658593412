import React from "react";
import { useEffect,useRef,useState } from "react";
import { Row, Col, Card,Stack,Button, Container } from "react-bootstrap";
import { BsFillLightningFill,BsArrowRightShort } from "react-icons/bs";
import { BiDollarCircle } from "react-icons/bi";
import { AiOutlineGlobal } from "react-icons/ai";
import { FaRegNewspaper } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import { SiMaterialdesignicons } from "react-icons/si";
import { AiOutlineGoogle } from 'react-icons/ai';
import { MdLocationOn,MdWhatsapp } from 'react-icons/md';
import { FaWhatsapp, FaFacebookSquare, FaInstagramSquare } from 'react-icons/fa';
import { AiOutlineUsergroupAdd, AiOutlineStar } from 'react-icons/ai';
import { Helmet } from "react-helmet";
import "./OurServices.css";


const ServiceCard = ({ title, description, icon }) => {
  return (
    <Card className="service-card">
      <Card.Body>
        {/* <div className="service-icon">{icon}</div> */}
        <Card.Title style={{fontWeight:"bold", color:"orange"}}>{title}</Card.Title>
        <Card.Text>{description}</Card.Text>
      </Card.Body>
    </Card>
  );
};

const OurServices = () => {

  const mapName = 'Preprockets Digital Services'; // Name of the map location
  const mapDescription = 'Google Maps showing Preprockets Digital Services'; // Description of the map
  const mapLatitude = '26.890708076660296'; // Latitude of the map location
  const mapLongitude = '81.05525187522358'; // Longitude of the map location
  const embedUrl = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3558.402821572815!2d81.05525187522358!3d26.890708076660296!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2ce531f503ce4a25%3A0x57166aee9b70844!2sPreprockets%20Digital%20Services!5e0!3m2!1sen!2sin!4v1712502768391!5m2!1sen!2sin'; // URL of embedded map

  const whatsappMessage = "Hello, I would like to connect with you regarding digital services.";
      

  const [animateHeading, setAnimateHeading] = useState(false);
  const headingRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const headingPosition = headingRef.current.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;

      if (headingPosition < windowHeight) {
        setAnimateHeading(true);
      } else {
        setAnimateHeading(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);



  const services = [

    {
      title: "Website Design and Development",
      description: "Create a stunning online presence that captivates visitors and converts them into loyal customers.",
      detailedDescription: "Discover Preprockets' comprehensive approach to website design and development. We offer cutting-edge UI/UX design and fully customizable solutions using platforms like WordPress, Shopify, Magento, Wix, and Odoo. Our team of professional developers specializes in modern responsive development, meticulous SEO optimization, and ongoing maintenance. We empower your digital presence with robust, scalable solutions that integrate seamlessly with industry giants like Google, driving conversions and enhancing user engagement. As leaders in the field, we deliver visually appealing websites that excel in functionality and performance.Experience our expertise in developing fully coded websites from scratch using modern web technologies. We craft custom web solutions that are responsive, secure, and optimized for performance. Utilizing frameworks such as React.js, Node.js, Angular, Python, Django, and more, we create dynamic and scalable websites tailored to your unique business needs. Whether you require e-commerce functionality, interactive features, or seamless user experiences, we ensure your website stands out in today's competitive digital landscape.",
      icon: <SiMaterialdesignicons />,
    },
    {
      title: "Search Engine Optimization (SEO)",
      description: "Boost your online visibility and rank higher on SERPs.",
      detailedDescription: "At Preprockets, our SEO services are tailored to drive your website's success. We specialize in comprehensive keyword research, meticulous on-page optimization, effective backlink building, and detailed performance tracking. Our approach ensures that your website not only ranks well but also attracts targeted traffic consistently.Utilizing industry-leading SEO optimization tools and strategies, including both on-page and off-page techniques, we enhance your website's visibility across search engines. From optimizing content and meta tags to building quality backlinks and analyzing performance metrics, we are dedicated to maximizing your online presence.Partner with Preprockets to leverage our expertise in SEO and propel your website to the top of search engine results. Whether you're aiming to increase organic traffic, improve search engine rankings, or boost conversions, our holistic SEO solutions are designed to achieve your digital marketing goals.",
      icon: <BsFillLightningFill />,
    },
    {
      title: "Pay-Per-Click (PPC) Advertising",
      description: "Drive targeted traffic and maximize ROI with PPC.",
      detailedDescription: "At Preprockets, we excel in PPC advertising strategies designed to maximize your ROI. Our comprehensive approach includes precise keyword targeting, continuous ad optimization, meticulous budget management, and insightful analytics. We specialize in driving high-quality traffic to your website, ensuring optimal performance and measurable results.Recognized among the best PPC agencies in India and globally, Preprockets offers tailored PPC solutions that align with your business goals. Whether you're looking to increase brand awareness, drive conversions, or boost sales, our expertise in PPC marketing ensures your campaigns achieve maximum effectiveness.Partner with Preprockets for top-notch PPC services that leverage cutting-edge strategies and industry-leading practices. From local PPC campaigns to global outreach, we optimize every aspect to deliver unparalleled results and sustainable growth for your business.",
      icon: <BiDollarCircle />,
    },
    {
      title: "Social Media Marketing (SMM)",
      description: "Engage your audience, build brand loyalty, and generate leads through impactful social media strategies.",
      detailedDescription: "Preprockets excels in social media marketing services designed to elevate your brand's presence and engagement. Our comprehensive approach includes expert content creation, strategic audience engagement, influencer partnerships, detailed analytics, and targeted campaigns. We specialize in enhancing brand visibility, fostering loyalty, and generating high-quality leads.Recognized as the best social media marketing company in India and globally, Preprockets delivers tailored strategies that align with your business goals. Whether you're a small business or a global enterprise, our expertise in social media marketing ensures impactful campaigns that resonate with your audience.Partner with Preprockets for top-tier social media marketing services that leverage cutting-edge tactics and industry-leading practices. From content strategy to campaign execution, we optimize every aspect to maximize your brand's reach and engagement.",
      icon: <AiOutlineGlobal />,
    },
    {
      title: "Content Marketing",
      description: "Create compelling content that resonates with your audience and establishes your brand as an authority in your industry.",
      detailedDescription: "Discover Preprockets' comprehensive content marketing approach designed to elevate your brand's authority and engagement. Our services encompass strategic content strategy development, expert content creation, targeted distribution, SEO optimization, and rigorous performance analysis.As the best content marketing company in India and globally, Preprockets specializes in crafting impactful content that resonates with your audience and positions your brand as an industry leader. Whether you seek B2B content marketing agencies or digital content solutions, our tailored strategies ensure measurable results and enhanced brand visibility.Partner with Preprockets to harness the power of content marketing. From conceptualization to distribution and performance evaluation, we optimize every step to drive audience engagement and achieve your business objectives effectively.",
      icon: <FaRegNewspaper />,
    },
    {
      title: "Email Marketing",
      description: "Nurture leads, drive conversions, and stay connected with your customers through personalized email campaigns.",
      detailedDescription: "Experience Preprockets' comprehensive email marketing services designed to enhance customer engagement and drive conversions. Our approach includes lead nurturing workflows, personalized email campaigns, segmentation, A/B testing, analytics, and continuous optimization.As the best email marketing company in India and a top choice for beginners and small businesses, Preprockets offers full-service email marketing solutions. Whether you need HubSpot email marketing integration or seek the best free email marketing tools, our strategies are tailored to maximize brand loyalty and ROI.Partner with Preprockets for effective email marketing that nurtures leads, boosts customer engagement, and achieves measurable results through targeted campaigns and continuous optimization efforts.",
      icon: <HiOutlineMail />,
    },
    {
      title: "Google Ads",
      description: "Formerly known as Google AdWords, Google Ads operates on a pay-per-click (PPC) model, where advertisers bid on keywords relevant to their business. It's highly effective for capturing leads with high purchase intent.",
      detailedDescription: "Discover Preprockets' expertise in Google Ads, formerly known as Google AdWords, a leading platform in digital advertising utilizing a pay-per-click (PPC) model. As the best Google Ads marketing company in India and globally, we specialize in tailored campaigns that drive website traffic, increase brand awareness, and generate leads and sales.Whether you're a small business seeking local visibility or require Google Ads agency services in Delhi or elsewhere, Preprockets offers strategic solutions to enhance your online presence. Our approach includes precise keyword bidding and campaign optimization to ensure maximum ROI and business growth.Partner with Preprockets for comprehensive Google Ads management that positions your business at the forefront of search results and relevant websites, driving targeted traffic and achieving your advertising goals.",
      icon: <AiOutlineGoogle />,
    },
    {
      title: "Local SEO",
      description: "Focuses on optimizing a business’s online presence to attract local customers through strategies like Google My Business optimization, local keyword targeting, and positive reviews.",
      detailedDescription: "Local SEO is pivotal in digital marketing, ensuring your business reaches local customers effectively online. At Preprockets, we specialize in Local SEO services, recognized as the best local SEO company in India, the world, and globally. Our expertise includes leveraging advanced technology to enhance visibility in local search results, driving foot traffic and increasing online presence.Whether you're a small business seeking tailored Local SEO services or need expert guidance from local SEO experts near you, Preprockets offers comprehensive solutions to elevate your local digital strategy. Explore our Local SEO services pricing and partner with us to optimize your business for local success.",
      icon: <MdLocationOn />,
    },
    {
      title: "WhatsApp Marketing",
      description: "Involves using WhatsApp as a tool for business communication, customer support, and promotional campaigns. Companies can connect with customers effectively through WhatsApp.",
      detailedDescription: "Explore the power of WhatsApp Marketing with Preprockets. We specialize in leveraging this messaging platform for seamless business communication, support, and promotional campaigns. Our services include Bulk WhatsApp Marketing services, comprehensive WhatsApp Marketing Software solutions, including pricing options and free tools for automation. Whether you're looking for Bulk WhatsApp Marketing software or effective WhatsApp SMS marketing strategies, we optimize engagement and streamline processes to drive business growth.",
      icon: <FaWhatsapp />,
    },
    {
      title: "Influencer Marketing",
      description: "Harnesses the influence of individuals with significant online followings to promote products or services, fostering authentic connections with the target audience.",
      detailedDescription: "Influencer Marketing harnesses the credibility and expansive reach of influencers to forge authentic connections with the audience. At Preprockets, we specialize in meticulously identifying, analyzing, and collaborating with influencers to cultivate impactful partnerships and amplify brand visibility. As one of the top influencer marketing companies in India, we excel in partnering with micro influencers and agencies across Lucknow, Delhi, and nationwide, ensuring strategic influencer marketing campaigns that resonate with your target audience.",
      icon: <AiOutlineUsergroupAdd />,
    },
    {
      title: "Facebook Ads",
      description: "A powerful platform for digital advertising, offering various ad formats and robust targeting options based on demographics, interests, and behaviors.",
      detailedDescription: "Facebook Ads represents a robust digital advertising platform offering extensive targeting options. At Preprockets, we harness AI to optimize strategies and effectively engage audiences through Facebook Ads. We are recognized among the top 10 best Facebook ads marketing companies in India, serving as the best Facebook advertising agency in India for 2024. As a leading Facebook ads agency, we specialize in delivering tailored solutions across India, including Adymize and other Facebook marketing companies near me.",
      icon: <FaFacebookSquare />,
    },
    {
      title: "Instagram Ads",
      description: "Provides businesses with a dynamic platform on the popular visual-centric social media network, leveraging engaging content and precise targeting.",
      detailedDescription: "Preprockets, based in Lucknow, utilizes Instagram Ads as a dynamic platform for businesses, employing AI technology for precise ad targeting and strategic insights. As your local Instagram marketing agency in Lucknow, we offer customized solutions to enhance your brand presence. Whether you're searching for an Instagram marketing agency near you or exploring Instagram marketing agency pricing, our expertise ensures effective campaigns that resonate. Ranked among the top 10 social media marketing companies in India, we serve clients nationwide, solidifying our reputation as a premier Instagram marketing agency. Discover why we're among the best social media marketing agencies in India, delivering impactful results for businesses across the country.",
      icon: <FaInstagramSquare />,
    },
    {
      title: "Online Reputation Management (ORM)",
      description: "Specializes in managing and enhancing online reputations through advanced AI technologies, proactively addressing potential reputation issues.",
      detailedDescription: "Preprockets specializes in Online Reputation Management (ORM), utilizing advanced AI technology to monitor, analyze, and effectively manage online reputations. As a leading ORM agency in India, we proactively address potential issues and enhance digital images for individuals and businesses alike. Whether you're looking for the top 10 best reputation management companies in India, comprehensive online reputation management services, packages for review management, or Indian ORM expertise, Preprockets delivers tailored solutions that safeguard and elevate your online presence. Trust us for unmatched ORM services that ensure your reputation shines in the digital landscape.",
      icon: <AiOutlineStar />,
    },
];
  
  return (
    <div>

<Helmet>
        <title>Our Services - Preprockets Digital Services</title>
        <meta name="description" content="Discover Preprockets Digital Services for expert digital marketing and web development solutions. Our services include website design, website development, strategic planning, SEO, social media management, PPC campaigns, content strategy, branding, digital transformation, customer engagement, marketing analytics, SMS marketing, email marketing, WhatsApp marketing, and Google Ads. Tailored to drive growth, enhance online visibility, and maximize ROI for your business success." />
        <meta name="keywords" content="digital marketing solutions, website design, website development, strategic planning, SEO services, social media management, PPC campaigns, content strategy, branding services, digital transformation, customer engagement, marketing analytics, SMS marketing, email marketing, WhatsApp marketing, Google Ads management, online visibility, business growth strategies, ROI optimization, sustainable business success" />
        <meta property="og:type" content="organization" />
        <meta property="og:url" content="https://www.preprockets.com" />
        <meta property="og:title" content="Preprockets Digital Services" />
        <meta property="og:description" content="At Preprockets Digital Services, we go beyond traditional digital marketing. We're your strategic partner in navigating the dynamic digital landscape, delivering tailored solutions that drive growth, enhance visibility, and maximize ROI. Our mission is clear: empower businesses to thrive in the digital age by unlocking their full potential and achieving sustainable success through innovative strategies." />
        <meta property="og:image" content="https://expensetracker12911.s3.amazonaws.com/Logo+Png-01-fotor-bg-remover-2024040211621.png" />

        <meta name="twitter:site" content="@Preprockets"/>
<meta name="twitter:title" content="Preprockets Digital Services"/>
<meta name="twitter:description" content="Preprockets Digital Services: Your trusted partner in digital success."/>
<meta name="twitter:image" content="https://pbs.twimg.com/profile_images/1775109624561356800/V9TrS4pd_400x400.jpg"/>


  <meta property="og:title" content="Preprockets Digital Services"/>
  <meta property="og:description" content="Preprockets Digital Services. Apne Business Ko De Ek Nayi Udaan!"/>
  <meta property="og:image" content="https://instagram.flko7-3.fna.fbcdn.net/v/t51.2885-19/435145071_1145834283075755_3259311141175043805_n.jpg?_nc_ht=instagram.flko7-3.fna.fbcdn.net&_nc_cat=111&_nc_ohc=BHsEQt5AztwQ7kNvgH-dP5K&edm=AEhyXUkBAAAA&ccb=7-5&oh=00_AYBbSArwYPew9XKWC6Qv7CqF_UF80nEQ4i6_BCb9AWGWFQ&oe=66984FAD&_nc_sid=8f1549%22"/>
  <meta property="og:url" content="https://www.instagram.com/preprockets/"/>
  <meta property="og:type" content="website"/>

  <meta property="og:title" content="Preprockets Digital Services"/>
  <meta property="og:description" content="Preprockets Digital Services. Apne Business Ko De Ek Nayi Udaan!"/>
  <meta property="og:image" content="https://scontent.flko7-4.fna.fbcdn.net/v/t39.30808-1/434336296_122096362100270093_746723831520368476_n.jpg?stp=dst-jpg_p200x200&_nc_cat=102&ccb=1-7&_nc_sid=f4b9fd&_nc_ohc=otuOfad8qEsQ7kNvgHhGWew&_nc_ht=scontent.flko7-4.fna&oh=00_AYB7Ylrmk9eoP7b0rSODstj7cCILg-BDYc12VEzLm-yc_w&oe=669861CB"/>
  <meta property="og:url" content="https://www.facebook.com/profile.php?id=61558102794628"/>
  <meta property="og:type" content="website"/>

  <meta property="og:title" content="Preprockets Digital Services"/>
  <meta property="og:description" content="Preprockets Digital Services. Apne Business Ko De Ek Nayi Udaan. preprockets.com . +91-921 925 3496"/>
  <meta property="og:image" content="https://yt3.googleusercontent.com/wSI9wtheE1wCAV4RfQnv_Bsr3aMu7KSjoxD_XK_XQCZqVRx_zYQR2eV1sOWMl6CIncOsHleH=s160-c-k-c0x00ffffff-no-rj"/>
  <meta property="og:url" content="https://www.youtube.com/@Preprockets"/>
  <meta property="og:type" content="video.other"/>

  <meta name="geo.position" content="26.8943375;81.0589693"/>
<meta name="geo.placename" content="Preprockets Digital Services | Digital Marketing Agency | Website Design Services | SEO Services | Social Media Marketing"/>
<meta name="geo.region" content="Country Code"/>
        
       
        
      </Helmet>

      <div className="our-services-top-div">

      <Container  style={{textAlign:"center",fontFamily:"Montserrat",fontSize: "1.5rem"}} className="pt-5"> 
        <span><a href="https://www.preprockets.com/" style={{color:"orange"}}>Home</a> / Our Services</span>
      </Container>

        <div  className="our-services-inner-div m-2">
          <Stack gap={2}>
        <div className="our-services-inner-div-heading mb-2">
       "Elevate Your Brand with Preprockets: It's Time to Go Beyond!"
        </div>

        <div className="our-services-inner-div-paragraph mb-2">
          <Container>
          Discover the transformative power of storytelling with Preprockets Digital Services as we revolutionize branding strategies. Going beyond logos and taglines, we specialize in crafting immersive brand narratives that deeply resonate with your audience. Our approach delves into the heart of your business, unveiling its unique story, values, and ambitions. Through strategic collaboration and leveraging top social media platforms and SEO tools like Facebook, Instagram, SEMrush, and Google Ads, we shape a compelling brand identity. Our goal is to not only capture attention but also foster enduring emotional connections. Let us illuminate your path to branding excellence, propelling your brand to new heights of visibility, engagement, and success.
          </Container>
        </div>
        </Stack>
        </div>


      </div>
 
    <div>
      {/* <div className="our-services-heading">Our Services</div>
      <div className="verticle-line"></div> */}
    <div className="expertise-container p-5" name="services">
     <div className="our-services-heading mb-5  p-1">Our Expertise & Services</div>
      <Row className="timeline mt-5">
        {services.map((service, index) => (
          <Col key={index} md={12} className="timeline-item">
            <div className="timeline-icon">{service.icon}</div>
            <div className="timeline-content">
              <h3>{service.title}</h3>
              <p>{service.detailedDescription}</p>
            </div>
            <ServiceCard className="service-card"
              title={service.title}
              description={service.description}
              icon={service.icon}
            />
          </Col>
        ))}
        <div className="line"></div>
      </Row>
    </div>
    </div>
    <div className="chat-with-us-outer-div">
      <div className="chat-with-us-inner-div">
     <Stack gap={2}>
      <div className="chat-with-us-heading">"Embark on your journey towards establishing a robust brand presence today."</div>
      <Stack direction="horizontal" gap={2}>
       <div ref={headingRef} className={`chat-with-us-paragraph ${animateHeading ? "chat-with-us-paragraph-animation" : ""}`}>Chat With Us To Know More.</div>
       <div ref={headingRef} className={`chat-with-us-btn ${animateHeading ? "chat-with-us-btn-animation" : ""}`}>
        <Button className="chat-with-us-btn-btn"
        
        onClick={() =>
          window.open(
            `https://api.whatsapp.com/send?phone=+919219253496&text=${encodeURIComponent(
              whatsappMessage
            )}`,
            "_blank"
          )
        }
        
        >Send Message Now <span><BsArrowRightShort className="chat-with-us-btn-icon" /></span></Button>
       </div>
      </Stack>
     </Stack>
     </div>
    </div>
    <MdWhatsapp
          className="whatsapp-icon"
          onClick={() =>
            window.open(
              `https://api.whatsapp.com/send?phone=+919219253496&text=${encodeURIComponent(
                whatsappMessage
              )}`,
              "_blank"
            )
          }
        />
    </div>
  );
};

export default OurServices;
